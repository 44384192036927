<template>
  <v-card flat>
    <v-card-text>
      <v-expand-transition v-if="showSocial">
        <social-login @connected="connected" />
      </v-expand-transition>
      <v-expand-transition v-else>
        <v-form
          ref="registerForm"
          v-model="valid"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <div class="mb-4">
            <p class="text-14 mb-1">
              Email
            </p>
            <v-text-field
              v-model="email"
              placeholder="example@mail.com"
              outlined
              :error-messages="errorMessages.email"
              :rules="emailRules"
              label="Email"
              dense
              hide-details="auto"
              class="mb-4"
            />
          </div>
          <div class="mb-4">
            <p class="text-14 mb-1">
              {{ $t("Password") }}
            </p>
            <v-text-field
              v-model="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="*********"
              outlined
              dense
              hide-details="auto"
              class="mb-4"
              :rules="passwordRules"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
              @click:append="isPasswordVisible = !isPasswordVisible"
            />
          </div>
          <div class="mb-4">
            <v-checkbox
              v-model="remember_me"
            >
              <template v-slot:label>
                <div>
                  {{ $t("Remember me") }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="mb-4">
            <v-btn
              :disabled="!valid"
              block
              color="primary"
              class="text-capitalize font-600"
              type="submit"
              :loading="loading"
            >
              {{ $t("Continuer") }}
            </v-btn>
          </div>
          <div class="text-14 text-center ">
            <router-link
              to="/forgout-password"
              class=" primary--text text--darken-1 font-600"
            >
              {{ $t("Mot de passe oublié ?") }}
            </router-link>
          </div>
<!--          <p>
            {{ errorMessages }}
          </p>-->
        </v-form>
      </v-expand-transition>
      <div class="py-2">
        <div class="text-center">
          <p
            v-if="showSocial"
            class="text-center"
          >
            {{ $t("Ou connecte-toi avec") }} <span
              class="cursor-pointer primary--text text--darken-1 font-600"
              @click.stop="showSocial = !showSocial"
            >{{ $t("E-mail") }}</span>
          </p>
          <p
            v-else
            class="text-center"
          >
            {{ $t("Ou connecte-toi avec") }} <span
              class="cursor-pointer primary--text text--darken-1 font-600"
              @click.stop="showSocial = !showSocial"
            >{{ $t("Compte social") }}</span>
          </p>
        </div>
      </div>
      <v-snackbar :color="snackbar.color" v-model="snackbar.active">
        {{ snackbar.text }}
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
  import { i18n } from '@/plugins/i18n'

  import { mapState, mapMutations } from 'vuex'
  import SocialLogin from './SocialLogin.vue'

  export default {
    name: 'LoginForm',
    components: { SocialLogin },

    props: {
      hideExtra: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showSocial: true,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        email: '',
        password: '',
        remember_me: null,
        errorMessages: { name: 'Is required' },
        termsRules: [v => !!v || i18n.t('Accept terms and conditions')],
        nameRules: [v => !!v || i18n.t('Name is required')],
        firstNameRules: [v => !!v || i18n.t('Firstname is required')],
        lastNameRules: [v => !!v || i18n.t('Firstname is required')],
        emailRules: [v => !!v || i18n.t('Email is required')],
        passwordRules: [v => !!v || i18n.t('Password is required')],
        snackbar: {},

      }
    },
    computed: {
      ...mapState({
        items: 'login',
        user: 'user',
        // totalItems: state => state.withdraws.length,
      }),
    },
    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      connected (data) {
        if (this.$route.path === '/checkout') {
          this.$emit('connected', data)
        } else {
          if (this.$route.path === '/login') {
            this.$router.push('/')
          } else {
            if (data === 'welcome') {
              this.$router.push('/welcome')
            } else {
              window.location.reload()
            }
          }
        }
      },
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()
        console.log('bame')
        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/login',
          data: {
            email: this.email,
            password: this.password,
            remember_me: this.remember_me,
          },
          validateStatus: (status) => {
            return true
          },
        }).catch(error => {
          this.loading = false
          console.log(error)
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          //localStorage.setItem('userData', JSON.stringify(response.data))
          //localStorage.setItem('login', true)
          this.setValue({ type: 'user', value: response.data })
          this.setValue({ type: 'login', value: true })
          this.$emit('connected', response.data)
          //if(process.env.local)
          localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))
          // ? On success redirect to home
          this.connected(response.data)
        }).then(() => {
          this.loading = false
        })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.snackbar.active = true
        this.snackbar.color = color
        this.snackbar.text = message
      },
    },
  }
</script>
