<template>
  <div
    class="card-carousel"
    @mouseover="stopTimer"
    @mouseleave="restartTimer"
  >
    <div
      v-if="autoSlideInterval && showProgressBar"
      class="progressbar"
    >
      <div :style="{width: progressBar + '%' }" />
    </div>
    <v-card
      flat
      class="card-img"
      :min-height="height"
    >
      <v-img
        :width="width"
        :height="height"
        :min-height="height"
        :src="currentImage"
        :alt="product.name"
        :title="product.name"
        @click="show"
      />
      <!--      <div class="images" v-viewer="{movable: false}">
        <img v-for="src in images" :src="src.large" :key="src.id">
      </div>-->
      <div
        v-if="showThumbs"
        class="actions"
      >
        <span
          class="prev"
          @click="prevImage"
        >
          &#8249;
        </span>
        <span
          class="next"
          @click="nextImage"
        >
          &#8250;
        </span>
      </div>
      <!--      <viewer :images="images">
        <img v-for="src in images" :key="src" :src="src">
      </viewer>-->
    </v-card>
    <div
      v-if="showThumbs"
      class="thumbnails"
    >
      <div
        v-for="(image, index) in images"
        :key="image.id"
        :class="['thumbnail-image', (activeImage == index) ? 'active' : '']"
        @click="activateImage(index)"
      >
        <v-avatar
          class="white rounded"
          tile
        >
          <v-img
            :src="image.thumb"
            alt=""
          />
        </v-avatar>
      </div>
    </div>
  </div>
</template>

<script>
  import 'viewerjs/dist/viewer.css'
  import VueViewer, { api as viewerApi } from 'v-viewer'
  import Vue from 'vue'
  export default {
    name: 'Carousel',
    props: ['startingImage', 'images', 'autoSlideInterval', 'showProgressBar', 'showThumbs', 'height', 'width','product'],

    data () {
      return {
        // Index of the active image
        activeImage: 0,
        // Hold the timeout, so we can clear it when it is needed
        autoSlideTimeout: null,
        // If the timer is stopped e.g. when hovering over the carousel
        stopSlider: false,
        // Hold the time left until changing to the next image
        timeLeft: 0,
        // Hold the interval so we can clear it when needed
        timerInterval: null,
        // Every 10ms decrease the timeLeft
        countdownInterval: 10,
      }
    },
    computed: {
      // currentImage gets called whenever activeImage changes
      // and is the reason why we don't have to worry about the
      // big image getting updated
      currentImage () {
        this.timeLeft = this.autoSlideInterval
        return this.images[this.activeImage].large
      },
      progressBar () {
        // Calculate the width of the progressbar
        return 100 - (this.timeLeft / this.autoSlideInterval) * 100
      },
    },
    created () {
      // Check if startingImage prop was given and if the index is inside the images array bounds
      if (this.startingImage &&
        this.startingImage >= 0 &&
        this.startingImage < this.images.length) {
        this.activeImage = this.startingImage
      }

      // Check if autoSlideInterval prop was given and if it is a positive number
      if (this.autoSlideInterval &&
        this.autoSlideInterval > this.countdownInterval) {
        // Start the timer to go to the next image
        this.startTimer(this.autoSlideInterval)
        this.timeLeft = this.autoSlideInterval
        // Start countdown to show the progressbar
        this.startCountdown()
      }
    },
    methods: {
      show () {
        /* const viewer = this.$el.querySelector('.images').$viewer
        viewer.show() */
        /* this.$viewerApi({
          images: this.images.map(e => e.large),
        }) */
        const $viewer = viewerApi({
          images: this.images.map(e => e.large),
        })
      },
      // Go forward on the images array
      // or go at the first image if you can't go forward
      nextImage () {
        var active = this.activeImage + 1
        if (active >= this.images.length) {
          active = 0
        }
        this.activateImage(active)
      },
      // Go backwards on the images array
      // or go at the last image
      prevImage () {
        var active = this.activeImage - 1
        if (active < 0) {
          active = this.images.length - 1
        }
        this.activateImage(active)
      },
      activateImage (imageIndex) {
        this.activeImage = imageIndex
      },
      // Wait until 'interval' and go to the next image;
      startTimer (interval) {
        if (interval && interval > 0 && !this.stopSlider) {
          var self = this
          clearTimeout(this.autoSlideTimeout)
          this.autoSlideTimeout = setTimeout(function () {
            self.nextImage()
            self.startTimer(self.autoSlideInterval)
          }, interval)
        }
      },
      // Stop the timer when hovering over the carousel
      stopTimer () {
        clearTimeout(this.autoSlideTimeout)
        this.stopSlider = true
        clearInterval(this.timerInterval)
      },
      // Restart the timer(with 'timeLeft') when leaving from the carousel
      restartTimer () {
        this.stopSlider = false
        clearInterval(this.timerInterval)
        this.startCountdown()
        this.startTimer(this.timeLeft)
      },
      // Start countdown from 'autoSlideInterval' to 0
      startCountdown () {
        if (!this.showProgressBar) return
        var self = this
        this.timerInterval = setInterval(function () {
          self.timeLeft -= self.countdownInterval
          if (self.timeLeft <= 0) {
            self.timeLeft = self.autoSlideInterval
          }
        }, this.countdownInterval)
      },
    },
  }
</script>

<style scoped>
.card-carousel {
    user-select: none;
    position: relative;
}

.progressbar {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    background-color: rgba(221, 221, 221, 0.25);
    z-index: 1;
}

.progressbar > div {
    background-color: rgba(255, 255, 255, 0.52);
    height: 100%;
}

.thumbnails {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    height: 100px;
    width: 100px;
}

.thumbnail-image > .v-avatar {
    width: 100%;
    height: auto;
    transition: all 250ms;
}

.thumbnail-image:hover > .v-avatar,
.thumbnail-image.active > .v-avatar {
    opacity: 0.6;
    box-shadow: 2px 2px 6px 1px var(--v-primary-base);
}

.card-img {
    position: relative;
    margin-bottom: 15px;

}

.card-img > .v-image {
    display: block;
    margin: 0 auto;
  max-width: 100%;
}

.actions {
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #585858;
}

.actions > span {
    cursor: pointer;
    transition: all 250ms;
    font-size: 45px;
}

.actions > span.prev {
    margin-left: 5px;
}

.actions > span.next {
    margin-right: 5px;
}

.actions > span:hover {
    color: #eee;
}
</style>
