<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div>
          <div class="px-3 px-md-10 py-8 ">
            <div class="text-center mb-2">
              <h3 class="mb-0">
                <router-link to="/">
                  <v-img
                    width="200"
                    src="@/assets/images/logo.png"
                    class="mx-auto"
                  />
                </router-link>
              </h3>
            </div>
            <div class="text-center mb-10">
              <h3 class="mb-3">
                {{ $t("Rejoins le mouvement de la seconde main et vends sans frais !") }}
              </h3>
            </div>
            <!--      <h5 class="font-600 grey&#45;&#45;text text&#45;&#45;darken-3 text-sm mb-9 text-center">Log in with email & password</h5>-->
            <login-form />
          </div>
          <div class="py-4 grey lighten-2">
            <div class="text-center">
              <span class="grey--text text--darken-1">{{ $t("Tu n'as pas de compte PLANETE MARKET ?") }} <router-link
                to="/sign-up"
                class="ms-2 primary--text text--darken-1 font-600"
              >{{ $t("S'inscrire") }}</router-link> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
<script>
  import LoginForm from '../components/LoginForm'
  export default {
    components: { LoginForm },
  }
</script>
