<template>
  <router-link
    :to="vendor.path || vendor.url"
    class="text-decoration-none d-flex "
  >
    <v-avatar
      :size="$vuetify.breakpoint.smAndDown?20:40"
      class="align-self-center"
    >
      <v-img
        :height="$vuetify.breakpoint.smAndDown?20:40"
        :width="$vuetify.breakpoint.smAndDown?20:40"
        :lazy-src="vendor.avatar_thumb || require('@/assets/images/avatars/1.png')"
        :src="vendor.avatar || require('@/assets/images/avatars/1.png')"
      />
    </v-avatar>
    <div class="ml-1 ml-md-2 justify-start align-self-center d-flex flex-column">
      <div class="text-truncate text-body-2 text-lg-body-1">
        {{ shortHtml(vendor.pseudo, nameLength) }}
      </div>
      <div
        v-if="showCountry"
        class="black--text  font-weight-thin"
      >
        {{ $t("Pays") }}: {{ vendor.country_code }}
      </div>
    </div>
  </router-link>
</template>

<script>

  export default {
    name: 'VendorLink',
    directives: {
    },
    components: {
    },
    props: {
      vendor: {
        type: Object,
        default: () => {},
      },
      nameLength: {
        type: Number,
        default: 30,
      },
      showCountry: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
    }),
    computed: {

    },

    methods: {

    },
  }
</script>
<style scoped>
  @media (max-width: 414px) {
    .text-body-2 {
      font-size: 0.8rem;
    }
  }
</style>
