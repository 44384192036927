<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col cols="12">
      <v-card
        dark
        :height="51"
        class=" "
        color="#4285F4"
        :title="isLogin?$t('Sign In with Google'):$t('Sign Up with Google')"
        @click="redirect('google')"
      >
        <v-avatar
          size="50"
          tile
        >
          <v-img src="@/assets/images/brands/btn_google_light_normal_ios.svg" />
        </v-avatar>
        <span
          class="heading mx-auto ml-3 font-weight-bold"
          style="font-size: 20px;vertical-align: middle"
        >{{ isLogin?$t("Sign In with Google"):$t("Sign Up with Google") }}</span>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card
        dark
        :height="51"
        class="pt-1 pl-2"
        color="#1877F2"
        :title="$t('Facebook')"
        @click="redirect('facebook')"
      >
        <v-avatar size="45">
          <v-img src="@/assets/images/brands/f_logo_RGB-White_58.png" />
        </v-avatar>
        <span
          class="heading mx-auto ml-3 font-weight-bold"
          style="font-size: 20px;vertical-align: middle"
        >{{ isLogin?$t("Login with Facebook"):$t("Sign Up with Facebook") }}</span>
      </v-card>
      <overlay v-if="loading" />
    </v-col>
  </v-row>
</template>

<script>
  import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
  import Overlay from './Overlay.vue'
  import { mapMutations } from 'vuex'

  const provider = new GoogleAuthProvider()
  const auth = getAuth()

  export default {

    directives: {
    },
    components: {
      Overlay,
    },
    props: {
      isLogin: { type: Boolean, default: true },
    },
    data: () => ({
      loginWin: null,
      loading: false,
    }),
    computed: {

    },
    created: function () {
      this.setUpEventListeners()
      auth.languageCode = this.$i18n.locale
    },
    destroyed: function () {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
      var eventer = window[eventMethod]
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
      window.removeEventListener(messageEvent, function () {})
    },
    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      redirect (owner) {
        if (owner === 'google') {
          signInWithPopup(auth, provider)
            .then((result) => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              const credential = GoogleAuthProvider.credentialFromResult(result)
              const token = credential.accessToken
              // The signed-in user info.
              const user = result.user
              // IdP data available using getAdditionalUserInfo(result)

              result.user.getIdToken().then(idToken => {
                this.sendTokenToServer(idToken)
              })
            }).catch((error) => {
              // Handle Errors here.
              const errorCode = error.code
              const errorMessage = error.message
              console.log(errorCode, errorMessage)
              // The email of the user's account used.
              const email = error.customData.email
              // The AuthCredential type that was used.
              const credential = GoogleAuthProvider.credentialFromError(error)
            // ...
            })
        } else {
          signInWithPopup(auth, provider)
            .then((result) => {
              // The signed-in user info.
              const user = result.user

              // This gives you a Facebook Access Token. You can use it to access the Facebook API.
              const credential = FacebookAuthProvider.credentialFromResult(result)
              const accessToken = credential.accessToken
              result.user.getIdToken().then(idToken => {
                this.sendTokenToServer(idToken)
              })
              // IdP data available using getAdditionalUserInfo(result)
              // ...
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code
              const errorMessage = error.message
              // The email of the user's account used.
              const email = error.customData.email
              // The AuthCredential type that was used.
              const credential = FacebookAuthProvider.credentialFromError(error)

              console.log(errorMessage)
              // ...
            })
        }
      },
      sendTokenToServer (token) {
        // eslint-disable-next-line no-undef
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/firebase_login',
          data: { token },
          validateStatus: (status) => {
            return true
          },
        }).catch(error => {
          this.loading = false
          console.log(error)
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          //localStorage.setItem('userData', JSON.stringify(response.data))
          //localStorage.setItem('login', true)
          this.setValue({ type: 'user', value: response.data })
          this.setValue({ type: 'login', value: true })
          this.$emit('connected', 'welcome')
          localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))
          // ? On success redirect to home
          /* window.location.href = '/' */
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      /* redirect (provider) {
        this.url = this.$store.state.baseUrl + '/social_login/redirect/' + provider
        // this.pop_dialog = true;
        var w = 500
        var h = 600

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

        const systemZoom = width / window.screen.availWidth
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        this.loginWin = window.open(this.url, this.$t('Login'),
                                    `
                scrollbars=yes,
                width=${w / systemZoom},
                height=${h / systemZoom},
                top=${top},
                left=${left}
                `,
        )
        var self = this
        this.loginWin.onclose(function () {
          //self.checkLogin()
        })

        return false
      }, */
      setUpEventListeners () {
        var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
        var eventer = window[eventMethod]
        var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
        var self = this
        eventer(messageEvent, function (e) {
                  // if (isNaN(e.data)) return;
                  switch (e.data.type) {
                    case 'success':
                      console.log('new message', e.data)
                      self.closePop()
                      break
                  }
                },
                false)
      },
      closePop () {
        this.$emit('connected')
      },
    },
  }
</script>
<style scoped>

</style>
