<template>
  <!-- card-hover-shadow  -->
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isBtn"
        value="right"
        v-bind="attrs"
        color="primary"
        :small="$vuetify.breakpoint.mdAndDown"
        v-on="on"
      >
        {{ $t("Annuler cet article") }}
      </v-btn>
      <v-list-item
        v-else
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>{{ $t("Annuler cet article") }}</v-list-item-title>
      </v-list-item>
    </template>
    <base-card>
      <v-card-title>
        {{ $t("Confirmer") }}
        <v-spacer />
        <v-btn
          class="mt-4"
          absolute
          right
          icon
          color="secondary"
          text
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            {{ $t("Quantité") }}: {{ item.quantity }}
          </v-col>
          <v-col
            cols="12"
          >
            {{ $t("Êtes-vous sûr de vouloir annuler cet article ?") }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="dialog=false"
        >
          {{ $t("Annuler") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="save"
        >
          {{ $t("Confirmer") }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
      >
        {{ snackbar.message }}
      </v-snackbar>
    </base-card>
  </v-dialog>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'
  import AddToCartButton from './AddToCartButton'

  export default {
    name: 'CancelArticle',
    components: {},
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      isBtn: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        dialog: false,
        loading: false,
        snackbar: {
          active: false,
          message: '',
          color: 'red',
        },
      }
    },
    computed: {
    },
    mounted () {
      this.loading = false
    },
    created () {
      this.loading = false
    },
    methods: {
      save () {
        this.loading = true
        axios({
          method: 'post',
          url: `/package/remove/item/${this.item.package_id}/${this.item.id}`,
          data: { },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          this.loading = false
          if (response.data.error) {
            this.snackbar.message = response.data.message
            this.snackbar.active = true
          } else {
            this.dialog = false
            this.$emit('removed')
          }
        }).then(() => {
          this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
